
import { bool } from 'vue-types';

import { moduleShape } from '~/types/module';
import { visual } from '@/types/visual';

export default {
    props: {
        ...moduleShape,
        visual: visual.isRequired,
        // front: bool().def(false),
        fullwidth: bool().def(false),
        fullheight: bool().def(false),
        useParallax: bool().def(true),
        useReveal: bool().def(true),
    },
    computed: {
        classes() {
            return [this.fullheight ? 'h-screen' : null];
        },
        containerClasses() {
            if (this.fullwidth) {
                return null;
            }

            return 'container content-grid';
        },
    },
};
